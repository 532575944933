@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100..900&display=swap');
@import url('http://fonts.googleapis.com/earlyaccess/nanumgothic.css');
@import url('https://rsms.me/inter/inter.css');

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
    width: 100%;

    margin: 0;
    overflow-x: hidden;
}

